import React, { ChangeEvent, useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// react hook form  and validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import { Box, Stack, Switch, Typography } from "@mui/material";
// Component
import InputField from "./InputField";
import SubmitButton from "../button/SubmitButton";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

type Props = {
    fieldKey: string;
    id: number;
    title: string;
    type: string;
    value: string;
    schema: any;
};
const SettingFieldForm = ({
    fieldKey,
    id,
    title,
    type,
    value,
    schema,
}: Props) => {
    // state
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            value: value,
        },
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });
    // submit form
    const onSubmit = (data: any) => {
        const formData = new FormData();
        formData.append("value", data.value);
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/settings/${id}`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم تعديل ${title}`);
                setSubmitting(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };

    // component ui
    if (type === "boolean")
        return (
            <TogglerField
                title={title}
                value={value}
                userToken={userToken}
                id={id}
            />
        );
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="10px"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <InputField
                label={title}
                errorMessage={errors?.value?.message?.toString()}
                registration={{ ...register("value") }}
                type={type}
                styles={{ flex: "1" }}
            />
            <SubmitButton label="تعديل" disabled={submitting} />
        </Box>
    );
};

export default SettingFieldForm;

type TogglerFieldProps = {
    title: string;
    value: string;
    userToken: string;
    id: number;
};
const TogglerField = ({ title, value, userToken, id }: TogglerFieldProps) => {
    const [switchValue, setSwitchValue] = useState(
        value.toLowerCase() === "true"
    );
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSwitchValue(e.target.checked);
        const formData = new FormData();
        formData.append("value", "" + e.target.checked);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/settings/${id}`, formData)
            .then((res: AxiosResponse) => {
                const alertMsg =
                    e.target.checked === true
                        ? `تم تفعيل ${title}`
                        : `تم تعطيل ${title}`;
                successAlert(alertMsg);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Typography variant="h6">{title}</Typography>
            <Stack direction="row" gap="10px">
                <Switch
                    size="small"
                    color="success"
                    checked={switchValue}
                    onChange={handleChange}
                />
                <Typography variant="body2">
                    {switchValue === true ? "مفعلة" : "معطلة"}
                </Typography>
            </Stack>
        </Stack>
    );
};
