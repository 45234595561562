import { ChangeEvent, useEffect, useRef, useState } from "react";
// react router
import { useParams, useNavigate } from "react-router";
// redux
import { useSelector } from "react-redux";
// react hook form  and validation
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editDigitalProductSchema } from "../../schemes/product/editDigitalProductSchema";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, Box, Switch, FormControlLabel } from "@mui/material";
// components
import {
    PageTitle,
    ImagePreview,
    SubmitButton,
    InputField,
    SelectCategory,
    SelectUnit,
    ErrorMessage,
    ThreeDotsLoader,
} from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

type FormValues = {
    img: File | null | string;
    name: string;
    price: number | undefined;
    final_price: number | undefined;
    quantity: number;
    ignore_quantity: boolean;
    description: string;
    categories: any;
    unit: any;
    csv: File | null;
    is_digital: boolean;
    is_disabled: boolean;
};
const EditDigitalProduct = () => {
    const navigate = useNavigate();
    const { productId } = useParams();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultImg, setDefaultImg] = useState("");
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            name: "",
            price: 0,
            final_price: 0,
            quantity: 0,
            ignore_quantity: false,
            description: "",
            categories: [],
            unit: "",
            csv: null,
            is_digital: true,
            is_disabled: false,
        },
        resolver: yupResolver(editDigitalProductSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        control,
        watch,
        reset,
        getValues,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        // reshape categoreis array
        const newCats: any = [];
        data.categories.forEach((cat: any) => {
            newCats.push(cat.id);
        });
        // overwrite new shape of categories to the new DAta
        const newData = { ...data, categories: newCats };
        // convert new data to formData
        const formData = new FormData();
        if (watch("ignore_quantity")) {
            data["quantity"] = 0;
        }
        for (let i in newData) {
            if (i == "categories") {
                newData.categories.forEach((cat: any) => {
                    formData.append("categories", cat);
                });
            } else {
                formData.append(i, newData[i]);
            }
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/items/${productId}`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم تعديل منتج باسم ${data.name}`);
                setSubmitting(false);
                navigate(-1)
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };

    // get product data
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/items/${productId}`)
            .then((res: AxiosResponse) => {
                reset(res.data);
                setDefaultImg(res.data.img);
                reset({
                    img: res.data.img,
                    name: res.data.name,
                    price: res.data.price,
                    final_price: res.data.final_price,
                    quantity: res.data.quantity,
                    ignore_quantity: res.data.ignore_quantity,
                    description: res.data.description,
                    categories: res.data.categories,
                    unit: JSON.stringify({ id: res.data.unit.id }),
                    is_digital: res.data.is_digital,
                    is_disabled: res.data.is_disabled,
                });
                setLoading(false);
            });
    }, []);
    // while page is loading
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="تعديل منتج غير ملموس" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message}
                        defaultImage={defaultImg}
                    />
                    <InputField
                        type="text"
                        label="اسم المنتج"
                        registration={{ ...register("name") }}
                        errorMessage={errors?.name?.message}
                    />
                    <InputField
                        type="number"
                        label="سعر المنتج"
                        registration={{ ...register("price") }}
                        errorMessage={errors?.price?.message}
                    />
                    <InputField
                        type="number"
                        label="نسبة التخفيض"
                        registration={{ ...register("final_price") }}
                        errorMessage={errors?.final_price?.message}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                    >
                        <Box flex="1">
                            <InputField
                                type="number"
                                label="الكمية"
                                registration={{ ...register("quantity") }}
                                errorMessage={errors?.quantity?.message}
                                disabled={
                                    watch("ignore_quantity") ? true : false
                                }
                                styles={{ width: "100%" }}
                            />
                        </Box>
                        <Controller
                            name="ignore_quantity"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...field}
                                            checked={watch("ignore_quantity")}
                                            onChange={(e) =>
                                                setValue(
                                                    "ignore_quantity",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="تجاهل الكمية"
                                />
                            )}
                        />
                    </Box>
                    <SelectUnit
                        control={control}
                        defaultValue={watch("unit")}
                    />
                    {errors?.unit ? (
                        <ErrorMessage errMessage={errors?.unit?.message} />
                    ) : (
                        <></>
                    )}
                    <SelectCategory
                        control={control}
                        watch={watch}
                        setValue={setValue}
                    />
                    {errors?.categories ? (
                        <ErrorMessage
                            errMessage={errors?.categories?.message}
                        />
                    ) : (
                        <></>
                    )}
                    <InputField
                        type="text"
                        label="وصف المنتج"
                        registration={{ ...register("description") }}
                        errorMessage={errors?.description?.message}
                        multiline={true}
                        minRows={3}
                        maxRows={6}
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="تعديل" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditDigitalProduct;
