import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// hooks
import usePageParams from "../../hooks/usePageParams";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, Box } from "@mui/material";
// components
import {
    PageTitle,
    SortBox,
    SearchBox,
    PaginationFilter,
    ProductCard,
    LinkButton,
    ThreeDotsLoader,
    ProductType,
} from "../../components";
// icon
import { MdAdd } from "react-icons/md";
// types
import { ReduxUserReducerState } from "../../types/global";

const Products = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const { page, setPage } = usePageParams();
    const [products, setProducts] = useState([]);
    const [sortValue, setSortValue] = useState<string>("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalItems, setTotalItems] = useState(24);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [dataChanged, setDataChanged] = useState(false);
    const [filterValues, setFilterValues] = useState({
        type: "",
    });
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(
                `/admin/items?page=${page}&paginate=${itemsPerPage}&sorts=${sortValue}&filters=${filterValues.type}&q=${searchValue}`
            )
            .then((res: AxiosResponse) => {
                setProducts(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            });
    }, [page, itemsPerPage, sortValue, dataChanged, searchValue, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="ادراة المنتجات" />
            <Stack direction="row" gap="20px">
                <LinkButton
                    text="اضافة منتج ملموس"
                    to="/add-non-digital-product"
                    icon={<MdAdd />}
                />
                <LinkButton
                    text="اضافة منتج غير ملموس"
                    to="/add-digital-product"
                    icon={<MdAdd />}
                />
            </Stack>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
                <ProductType
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <ThreeDotsLoader />
                ) : products.length === 0 ? (
                    <p>لا توجد عناصر لعرضها</p>
                ) : (
                    products.map((product) => {
                        const {
                            id,
                            name,
                            img,
                            price,
                            is_digital,
                            is_disabled,
                        } = product;
                        return (
                            <ProductCard
                                key={id}
                                id={id}
                                name={name}
                                imgSrc={img}
                                price={price}
                                is_disabled={is_disabled}
                                setDataChanged={setDataChanged}
                                to={
                                    is_digital
                                        ? `/edit-digital-product/${id}`
                                        : `/edit-non-digital-product/${id}`
                                }
                            />
                        );
                    })
                )}
            </Box>
            {!loading ? (
                <PaginationFilter
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={page}
                    setCurrentPage={setPage}
                />
            ) : (
                <></>
            )}
        </Stack>
    );
};

export default Products;
