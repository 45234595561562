import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// react-hook-form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, FormControlLabel, Switch } from "@mui/material";
// componennts
import {
    PageTitle,
    SubmitButton,
    InputField,
    SelectCategory,
} from "../../components";
// schema
import { addQuestionSchema } from "../../schemes/question/addQuestionSchema";
import { ReduxUserReducerState } from "../../types/global";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// form values type
type FormValues = {
    question: string;
    answer: string;
    category: any | null;
    is_disabled: boolean;
};

const AddQuestion = () => {
    const navigate = useNavigate();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            question: "",
            answer: "",
            category: null,
            is_disabled: false,
        },
        resolver: yupResolver(addQuestionSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .post(`/admin/faq`, data)
            .then((response: AxiosResponse) => {
                setSubmitting(false);
                successAlert("تم اضافة سؤال جديد");
                reset();
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة سؤال شائع" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="text"
                        registration={{ ...register("question") }}
                        label="السؤال"
                        errorMessage={errors?.question?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("answer") }}
                        label="الاجابة"
                        errorMessage={errors?.answer?.message}
                        multiline={true}
                        minRows={2}
                        maxRows={6}
                    />
                    <SelectCategory
                        getValues={getValues}
                        errorMessage={errors?.category?.message?.toString()}
                        setValue={setValue}
                        control={control}
                        multiple={false}
                        name="category"
                        categoriesParent={
                            process.env.REACT_APP_FAQS_CATS_PARENT_ID
                        }
                    />
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="انشاء" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddQuestion;
