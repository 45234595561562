import React, { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// hooks
import usePageParams from "../../hooks/usePageParams";
// mui
import { Stack, Box } from "@mui/material";
// components
import {
    PageTitle,
    SearchBox,
    SortBox,
    LinkButton,
    PaginationFilter,
    QuestionCard,
    ThreeDotsLoader,
} from "../../components";
// icon
import { MdAdd } from "react-icons/md";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";

const CommonQuestions = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const { page, setPage } = usePageParams();
    const [sortValue, setSortValue] = useState<string>("-created_at");
    const [searchValue, setSearchValue] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalItems, setTotalItems] = useState(24);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [faq, setFaq] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);
    const [filterValues, setFilterValues] = useState({
        status: "",
    });
    // get all areas
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(
                `/admin/faq?q=${searchValue}&sorts=${sortValue}&page=${page}&paginate=${itemsPerPage}&filters=${filterValues.status}`
            )
            .then((res: AxiosResponse) => {
                setFaq(res.data.data);
                setTotalItems(res.data.meta.total);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, [searchValue, page, itemsPerPage, sortValue, dataChanged, filterValues]);
    return (
        <Stack gap="20px">
            <PageTitle title="الاسئلة الاكثر تكرارا" />
            <Stack>
                <LinkButton
                    text="اضافة سؤال"
                    to="/add-question"
                    icon={<MdAdd />}
                />
            </Stack>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flexWrap="wrap"
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            <Stack gap="10px">
                {loading ? (
                    <ThreeDotsLoader />
                ) : faq.length === 0 ? (
                    <p className="center-text">لا توجد عناصر لعرضها</p>
                ) : (
                    faq.map((item: any) => (
                        <QuestionCard
                            key={item.id}
                            id={item.id}
                            question={item?.question}
                            answer={item?.answer}
                            categoryName={item?.category?.name}
                            setDataChanged={setDataChanged}
                            checked={item?.is_disabled}
                        />
                    ))
                )}
            </Stack>
            <PaginationFilter
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={page}
                setCurrentPage={setPage}
            />
        </Stack>
    );
};

export default CommonQuestions;
