import * as yup from "yup";

const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;

export const addNonDigitalProductSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            "fileSize",
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : "t")
        )
        .test("fileType", "امتداد الصورة يجب ان يكون jpg , png", (value) =>
            value != null
                ? ["image/jpg", "image/png", "image/jpeg"].includes(value.type)
                : "t"
        )
        .test(
            "fileRequired",
            "يجب ادخال صورة المنتج",
            (value) => value !== null
        ),
    name: yup
        .string()
        .min(3, "اسم المنج قصير جدا")
        .required("ادخل البريد الالكترونى"),
    price: yup
        .number()
        .typeError("يجب ان يكون سعر المنتج رقم ")
        .min(0.001, "سعر المنتج يجب ان يكون رقم اكبر")
        .required("ادخل سعر المنتج"),
    final_price: yup
        .number()
        .typeError("السعر النهائي يجب ان يكون رقم")
        .max(
            yup.ref("price"),
            "نسبة الخصم يجب ان يكون السعر بعد الخصم اكبر من او يساوى السعر الاساسى"
        ),
    ignore_quantity: yup.boolean(),
    quantity: yup
        .number()
        .typeError("يجب ادخال الكمية المتاحة للمنتج وان تكون رقم ")
        // .min(0, "سعر المنتج يجب ان يكون رقم اكبر من او يساوى 0")
        .when("ignore_quantity", ([ignore_quantity], schema) => {
            return ignore_quantity
                ? schema
                : schema
                      .min(0, "يجب ان تكون الكمية اكبر من او تساوى 1")
                      .required("يجب ادخال الكمية المتاحة للمنتج");
        }),
    categories: yup
        .mixed()
        .test("check-value", "اختر الفئة خاصة المنتج", (value) => {
            return value.length;
        }),
    unit: yup.mixed().test("check-value", "اختر الوحدة", (value) => {
        return value;
    }),
    description: yup.string().min(15, "وصف المنتج قصير جدا"),
});
