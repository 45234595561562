import React, { useState, useEffect } from "react";
// react redux
import { useSelector } from "react-redux";
// react rouoter
import { useParams } from "react-router";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// form
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// mui
import {
    Stack,
    FormControlLabel,
    Switch,
    Typography,
    Button,
} from "@mui/material";
// components
import {
    SideTitle,
    SubmitButton,
    InputField,
    SelectGender,
    ThreeDotsLoader,
} from "../../components";
// scheme
// import { editUserSchema } from "../../schemes/user/editUserSchema";
// types
import { ReduxUserReducerState } from "../../types/global";
// alerts
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from "../../utils/alerts/alerts";

type FormValues = {
    name: string;
    username: string;
    password: string;
    language: string;
    user: {
        id: string;
    };
};

const EditUserAccounts = () => {
    const { userId } = useParams<string>();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    // states
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [currentUserAccounts, setCurrentUserAccounts] = useState([]);
    // form config
    const form = useForm<FormValues>({
        defaultValues: {
            name: "",
            username: "",
            password: "",
            language: "en",
            user: {
                id: userId,
            },
        },
        // resolver: yupResolver(editUserSchema),
        mode: "all",
    });
    const {
        register,
        control,
        watch,
        setValue,
        reset,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .post(`/admin/accounts?lang=en`, data)
            .then((response: AxiosResponse) => {
                setSubmitting(false);
                successAlert("تم  اضافة حساب بوابة مشتركية للمستخدم ");
                fetchCurrentUserAccounts();
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // function that fetch current user accounts
    const fetchCurrentUserAccounts = () => {
        EnhancedAxios(null, userToken)
            .get(`/admin/accounts?filters=user_id:${userId}`)
            .then((res: AxiosResponse) => {
                setCurrentUserAccounts(res.data.data);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    // get current user accounts
    useEffect(() => {
        fetchCurrentUserAccounts();
    }, []);
    return (
        <Stack width="100%" maxWidth="700px" gap="10px">
            <SideTitle title="بيانات حسابات بوابة المشتركية" />
            <Typography variant="body2">اضافة حساب جديد</Typography>

            <Stack
                width="100%"
                gap="10px"
                direction="row"
                component="form"
                flexWrap="wrap"
                onSubmit={handleSubmit(onSubmit)}
            >
                <InputField
                    type="text"
                    registration={{ ...register("name") }}
                    label="الاسم "
                    errorMessage={errors?.name?.message}
                    styles={{ flex: "1", minWidth: "180px" }}
                />
                <InputField
                    type="text"
                    registration={{ ...register("username") }}
                    label="اسم المستخدم"
                    errorMessage={errors?.username?.message}
                    styles={{ flex: "1", minWidth: "180px" }}
                />
                <InputField
                    type="password"
                    registration={{ ...register("password") }}
                    label="الرقم السرى"
                    errorMessage={errors?.password?.message}
                    styles={{ flex: "1", minWidth: "180px" }}
                />
                <SubmitButton label="اضافة حساب" disabled={submitting} />
            </Stack>
            <Stack width="100%" gap="10px">
                <Typography variant="body2">الحسابات السابقة</Typography>
                {currentUserAccounts.length === 0 ? (
                    <Typography variant="subtitle2">
                        لا يوجد حسابات لهذا المستخدم
                    </Typography>
                ) : (
                    currentUserAccounts.map((account: any) => (
                        <ExistAccountData
                            key={account?.id}
                            userToken={userToken}
                            id={account?.id}
                            username={account?.username}
                            password={account?.password}
                            name={account?.name ?? ""}
                            fetchCurrentUserAccounts={fetchCurrentUserAccounts}
                        />
                    ))
                )}
            </Stack>
        </Stack>
    );
};

export default EditUserAccounts;
type Props = {
    id: string;
    username: string;
    password: string;
    userToken: string;
    name: string;
    fetchCurrentUserAccounts: any;
};
const ExistAccountData = ({
    id,
    username,
    password,
    userToken,
    fetchCurrentUserAccounts,
    name,
}: Props) => {
    const [submitting, setSubmitting] = useState(false);
    const [deleted, setDeleted] = useState(false);
    // form config
    const form = useForm<FormValues>({
        defaultValues: {
            username: username,
            password: password,
            name: name,
        },
        mode: "all",
    });
    const {
        register,
        control,
        watch,
        setValue,
        reset,
        formState: { errors, dirtyFields },
        handleSubmit,
    } = form;
    // submit edit
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        const newData = { password: data.password };
        setSubmitting(true);
        EnhancedAxios(null, userToken)
            .put(`/admin/accounts/${id}`, newData)
            .then((response: AxiosResponse) => {
                setSubmitting(false);
                successAlert(`تم تعديل الرقم السرى الخاص بى ${username} `);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    const deleteAccount = () => {
        EnhancedAxios(null, userToken)
            .delete(`/admin/accounts/${id}`)
            .then((res: AxiosResponse) => {
                successAlert("تم حذف الحساب");
                setDeleted(true);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    const deleteAccountConfirmation = () => {
        askForConfirmationAlert(
            "هل انت متاكد انك تريد حذف الحساب",
            deleteAccount
        );
    };
    return (
        <Stack
            width="100%"
            gap="10px"
            direction="row"
            component="form"
            sx={{
                display: deleted ? "none" : "flex",
            }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <InputField
                type="text"
                registration={{ ...register("name") }}
                label="الاسم"
                errorMessage={errors?.name?.message}
                styles={{ flex: "1", minWidth: "150px" }}
                readOnly={true}
            />
            <InputField
                type="text"
                registration={{ ...register("username") }}
                label="اسم المستخدم"
                errorMessage={errors?.username?.message}
                styles={{ flex: "1", minWidth: "150px" }}
                readOnly={true}
            />
            <InputField
                type="password"
                registration={{ ...register("password") }}
                label="الرقم السرى"
                errorMessage={errors?.password?.message}
                styles={{ flex: "1", minWidth: "150px" }}
            />
            <SubmitButton label="تعديل حساب" disabled={submitting} />
            <Button
                type="button"
                variant="outlined"
                color="error"
                onClick={deleteAccountConfirmation}
            >
                حذف
            </Button>
        </Stack>
    );
};
