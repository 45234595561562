import axios, { AxiosInstance, CreateAxiosDefaults } from "axios";
// jwt decoder library
import jwt_decode from "jwt-decode";

type EnhancedAxiosParams = (
    contentType?: string | null,
    token?: string | null
) => AxiosInstance | 0 | any;
// Create an instance of Axios
const EnhancedAxios: EnhancedAxiosParams = (contentType, token) => {
    if (isTokenExpired()) {
        window.localStorage.removeItem("flexnet_user_token");
        // warningAlert("انتهت فترة سماحية الدخول, برجاء تسجيلالدخول مرة اخرى.");
        window.location.href = "/login";
        return 0;
    } else {
        return axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            withCredentials: true,
            headers: {
                "Content-Type": contentType ?? "application/json",
                Authorization: token ? `Bearer ${token}` : undefined,
            },
        });
    }
};

function isTokenExpired() {
    if (window.localStorage.getItem("flexnet_user_token")) {
        const token = window.localStorage.getItem("flexnet_user_token");
        const decodedToken: any = jwt_decode(`${token}`);
        return decodedToken.exp * 1000 <= Date.now();
    }
    return false;
}

export default EnhancedAxios;
