import { ChangeEvent, useRef, useState } from "react";
// redux
import { useSelector } from "react-redux";
// react router
import { useNavigate } from "react-router";
// react hook form  and validation
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDigitalProductSchema } from "../../schemes/product/addDigitalProductSchema";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import {
    Stack,
    Typography,
    Button,
    Box,
    Switch,
    FormControlLabel,
} from "@mui/material";
// components
import {
    PageTitle,
    ImagePreview,
    SubmitButton,
    InputField,
    SelectCategory,
    SelectUnit,
    ErrorMessage,
} from "../../components";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";

type FormValues = {
    img: File | null;
    name: string;
    price: number | undefined;
    final_price: number | undefined;
    quantity: number;
    ignore_quantity: boolean;
    description: string;
    categories: any;
    unit: any;
    csv: File | null;
    is_digital: boolean;
    is_disabled: boolean;
};
const AddDigitalProduct = () => {
    const codesFileRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [codesFileName, setCodesFileName] = useState("");
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            name: "",
            price: 0,
            final_price: 0,
            quantity: 0,
            ignore_quantity: false,
            description: "",
            categories: [],
            unit: "",
            csv: null,
            is_digital: true,
            is_disabled: false,
        },
        resolver: yupResolver(addDigitalProductSchema),
        mode: "all",
    });
    const {
        register,
        setValue,
        control,
        watch,
        reset,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        const newCats: any = [];
        data.categories.forEach((cat: any) => {
            newCats.push(cat.id);
        });
        const newData = { ...data, categories: newCats };
        const formData = new FormData();
        if (watch("ignore_quantity")) {
            data["quantity"] = 0;
        }
        for (let i in newData) {
            if (i == "categories") {
                newData[i].forEach((cat: any, index: number) => {
                    formData.append(`categories`, cat);
                });
            } else {
                formData.append(i, newData[i]);
            }
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/items`, formData)
            .then((res: AxiosResponse) => {
                successAlert(`تم اضافة منتج باسم ${data.name}`);
                setSubmitting(false);
                reset();
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    // when input file changed
    const codesFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const file: File | null = e.target.files ? e.target.files[0] : null;
        if (file) {
            setCodesFileName(file.name);
            setValue("csv", file);
        }
    };
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة منتج غير ملموس" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message}
                        IPObjectFit="contain"
                    />
                    <InputField
                        type="text"
                        label="اسم المنتج"
                        registration={{ ...register("name") }}
                        errorMessage={errors?.name?.message}
                    />
                    <InputField
                        type="number"
                        label="سعر المنتج"
                        registration={{ ...register("price") }}
                        errorMessage={errors?.price?.message}
                    />
                    <InputField
                        type="number"
                        label="السعر النهائي"
                        registration={{ ...register("final_price") }}
                        errorMessage={errors?.final_price?.message}
                    />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                    >
                        <Box flex="1">
                            <InputField
                                type="number"
                                label="الكمية"
                                registration={{ ...register("quantity") }}
                                errorMessage={errors?.quantity?.message}
                                disabled={
                                    watch("ignore_quantity") ? true : false
                                }
                                styles={{ width: "100%" }}
                            />
                        </Box>
                        <Controller
                            name="ignore_quantity"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} />}
                                    label="تجاهل الكمية"
                                />
                            )}
                        />
                    </Box>
                    <SelectUnit control={control} />
                    {errors?.unit ? (
                        <ErrorMessage errMessage={errors?.unit?.message} />
                    ) : (
                        <></>
                    )}
                    <SelectCategory
                        control={control}
                        watch={watch}
                        setValue={setValue}
                    />
                    {errors?.categories ? (
                        <ErrorMessage
                            errMessage={errors?.categories?.message}
                        />
                    ) : (
                        <></>
                    )}
                    <InputField
                        type="text"
                        label="وصف المنتج"
                        registration={{ ...register("description") }}
                        errorMessage={errors?.description?.message}
                        multiline={true}
                        minRows={3}
                        maxRows={6}
                    />
                    <input
                        type="file"
                        accept=".csv"
                        {...register("csv")}
                        ref={codesFileRef}
                        onChange={codesFileChanged}
                        style={{ display: "none" }}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => codesFileRef.current?.click()}
                    >
                        رفع حزمة الكروت{" "}
                    </Button>
                    {codesFileName !== "" && (
                        <Typography variant="subtitle2" textAlign="center">
                            تم رفع : {codesFileName}
                        </Typography>
                    )}
                    {errors?.csv ? (
                        <Typography
                            fontSize="12px"
                            color="error"
                            textAlign="center"
                        >
                            {errors?.csv?.message}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="اضافة" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddDigitalProduct;
