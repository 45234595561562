import { useEffect, useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Divider, Stack } from "@mui/material";
// componennts
import {
    PageTitle,
    SettingFieldForm,
    SideTitle,
    ThreeDotsLoader,
    EditAdminData,
} from "../../components";

import * as yup from "yup";
import { ReduxUserReducerState } from "../../types/global";

const Settings = () => {
    // state
    const [loading, setLoading] = useState(true);
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/admin/settings?page=1&paginate=200`)
            .then((res: AxiosResponse) => {
                const settingsData = res.data.data;
                settingsData.forEach((stg: any, index: number) => {
                    aboutAppFieldsInfo.forEach((f: any, index) => {
                        if (stg.key === f.fieldKey) {
                            f.value = stg.value;
                            f.id = stg.id;
                            return;
                        }
                    });
                    SocialLinksFieldsInfo.forEach((f: any, index) => {
                        if (stg.key === f.fieldKey) {
                            f.value = stg.value;
                            f.id = stg.id;
                            return;
                        }
                    });
                    storeInfoFields.forEach((f: any, index) => {
                        if (stg.key === f.fieldKey) {
                            f.value = stg.value;
                            f.id = stg.id;
                            return;
                        }
                    });
                });
                setLoading(false);
            });
    }, []);

    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="الضبط" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="800px"
                    gap="10px"
                    divider={<Divider />}
                >
                    {/* about info fields  */}
                    {aboutAppFieldsInfo.length ? (
                        <Stack width="100%" gap="10px">
                            <EditAdminData />

                            <SideTitle title="حول التطبيق" />
                            {aboutAppFieldsInfo.map((field: any) => (
                                <SettingFieldForm
                                    key={field.key}
                                    id={field.id}
                                    fieldKey={field.fieldKey}
                                    title={field.title}
                                    value={field.value}
                                    type={field.type}
                                    schema={field.schema}
                                />
                            ))}
                        </Stack>
                    ) : (
                        <></>
                    )}
                    {/* social links fields  */}
                    {SocialLinksFieldsInfo.length ? (
                        <Stack width="100%" gap="10px">
                            <SideTitle title="وسائل التواصل" />
                            {SocialLinksFieldsInfo.map((field: any) => (
                                <SettingFieldForm
                                    key={field.key}
                                    id={field.id}
                                    fieldKey={field.fieldKey}
                                    title={field.title}
                                    value={field.value}
                                    type={field.type}
                                    schema={field.schema}
                                />
                            ))}
                        </Stack>
                    ) : (
                        <></>
                    )}
                    {/* store info  */}
                    {storeInfoFields.length ? (
                        <Stack width="100%" gap="10px">
                            <SideTitle title="خصائص المتجر" />
                            {storeInfoFields.map((field: any) => (
                                <SettingFieldForm
                                    key={field.key}
                                    id={field.id}
                                    fieldKey={field.fieldKey}
                                    title={field.title}
                                    value={field.value}
                                    type={field.type}
                                    schema={field.schema}
                                />
                            ))}
                        </Stack>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Settings;
const phoneRegex = new RegExp(
    /[+]?([0-9]{3})?\s?[\-]?[0-9]{2,3}\s?[\-]?[0-9]{3}\s?[\-]?[0-9]{4}/
);
const aboutAppFieldsInfo: any[] = [
    {
        id: 0,
        fieldKey: "about",
        title: "حول التطبيق",
        value: "",
        type: "text",
        schema: yup.object().shape({
            value: yup.string().required("ادخل معلومات عن التطبيق"),
        }),
    },
];
const SocialLinksFieldsInfo: any[] = [
    {
        id: 0,
        fieldKey: "app_email_url",
        title: "البريد الالكترونى",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().email("ادخل البريد الالكترونى بشكل صحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "app_facebook_url",
        title: "فيسبوك",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل الربط الصحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "app_twitter_url",
        title: "تويتر",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل الربط الصحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "app_instagram_url",
        title: "انستجرام",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل الربط الصحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "app_telegram_url",
        title: "تيليجرام",
        value: "",
        type: "url",
        schema: yup.object().shape({
            value: yup.string().url("ادخل الربط الصحيح"),
        }),
    },

    {
        id: 0,
        fieldKey: "app_whatsapp_url",
        title: "الواتساب",
        value: "",
        type: "tel",
        schema: yup.object().shape({
            value: yup
                .string()
                .matches(phoneRegex, "كتابة رقم الهاتف مسبوق بكود الدولة")
                .required("ادخل الرقم الصحيح"),
        }),
    },
    {
        id: 0,
        fieldKey: "app_phone",
        title: "رقم الهاتف",
        value: "",
        type: "tel",
        schema: yup.object().shape({
            value: yup
                .string()
                .matches(phoneRegex, "كتابة رقم الهاتف مسبوق بكود الدولة")
                .required("ادخل الرقم الصحيح"),
        }),
    },
];
const storeInfoFields: any[] = [
    {
        id: 0,
        fieldKey: "free_delivery",
        title: "اقل قيمة للتوصيل المجانى",
        value: "",
        type: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  مدة الانتظار يجب ان تكون رقم")
                .required("ادخل اقل قيمة للتوصيل المجانى")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        id: 0,
        fieldKey: "invoice_minimum",
        title: "الحد الادنى للطلب من المتجر",
        value: "",
        type: "number",
        schema: yup.object().shape({
            value: yup
                .number()
                .typeError("  مدة الانتظار يجب ان تكون رقم")
                .required("ادخل الحد الادنى للطلب من المتجر")
                .min(0, "القيمة يجب ان تكون اكبر من الصفر"),
        }),
    },
    {
        id: 0,
        fieldKey: "show_store",
        title: "حالة المتجر",
        value: "",
        type: "boolean",
        schema: yup.object().shape({
            value: yup.boolean(),
        }),
    },
];
