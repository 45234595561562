import { useState } from "react";
// router
import { useParams } from "react-router";
// mui
import { Stack, Button } from "@mui/material";
// components
import {
    PageTitle,
    EditUserForm,
    EditUserAccounts,
    ChangeUserBalanceModal,
} from "../../components";

const EditUser = () => {
    const { userId } = useParams<string>();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    return (
        <Stack gap="20px">
            <PageTitle title="تعديل المستخدم" />
            <Stack alignItems="center" spacing={1}>
                <Stack
                    alignItems="flex-end"
                    width="100%"
                    maxWidth="700px"
                    margin="auto"
                >
                    <Button onClick={() => setOpen(true)} variant="outlined">
                        تعديل رصيد المستخدم
                    </Button>
                    <ChangeUserBalanceModal
                        open={open}
                        handleClose={handleClose}
                    />
                </Stack>
                <EditUserForm />
                <EditUserAccounts />
            </Stack>
        </Stack>
    );
};

export default EditUser;
