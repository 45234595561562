import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGridSwitch, LinkButton } from "../../components";

export const deliverersCols: GridColDef[] = [
    {
        field: "name",
        headerName: "الاسم",
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "لا يوجد",
    },
    {
        field: "email",
        headerName: "البريد الالكترونى",
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.contactable?.email ?? "لا يوجد",
    },
    {
        field: "city",
        headerName: "المدينة",
        width: 170,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.address?.city?.name ?? "لا يوجد",
    },
    {
        field: "area",
        headerName: "المنطقة",
        width: 170,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.address?.area?.name ?? "لا يوجد",
    },
    {
        field: "status",
        headerName: "الحالة",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(DataGridSwitch, {
                checked: params?.row?.is_disabled,
                endpointPath: `/admin/distributors/${params?.row?.id}`,
            }),
    },
    {
        field: "edit",
        headerName: "تعديل",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(LinkButton, {
                text: "تعديل",
                to: `/edit-deliverer/${params.row.id}`,
                size: "small",
                variant: "outlined",
            }),
    },
];
