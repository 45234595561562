import { useState } from "react";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
// form
import { useForm, SubmitHandler } from "react-hook-form";
// components
import InputField from "./InputField";
import SubmitButton from "../button/SubmitButton";
// types
import { ReduxUserReducerState } from "../../types/global";
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
type Props = {
    open: boolean;
    handleClose: any;
};
type FormValues = {
    title: string;
    body: string;
};
const PushUserNotification = ({ handleClose, open }: Props) => {
    const notifiedUserData = useSelector((state: any) => state.notify.user);
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    // --------------------------- form handle ---------------------------
    const form = useForm<FormValues>({
        defaultValues: {
            title: "",
            body: "",
        },
        mode: "all",
    });
    const {
        register,
        getValues,
        setValue,
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = form;
    const onSubmit: SubmitHandler<FormValues> = (data) => {
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/users/${notifiedUserData.id}/notify`, data)
            .then((res: AxiosResponse) => {
                setSubmitting(false);
                successAlert("تم ارسال الاشعار بنجاح");
                reset();
            })
            .catch((error: AxiosError) => {
                setSubmitting(false);
                errorAlert(error);
            });
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>ارسال اشعار الى {notifiedUserData.name} </DialogTitle>
            <DialogContent
                sx={{
                    minWidth: { xs: "290px", sm: "500px" },
                    width: "100%",
                }}
            >
                <Stack
                    gap="10px"
                    width="100%"
                    minWidth="290px"
                    component="form"
                    py={4}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <InputField
                        type="text"
                        label="عنوان الاشعار"
                        registration={{
                            ...register("title", {
                                required: {
                                    value: true,
                                    message: "يجب ان يكون هناك عنوان للاشعار",
                                },
                            }),
                        }}
                        errorMessage={errors?.title?.message}
                    />
                    <InputField
                        type="text"
                        label="محتوى الاشعار"
                        registration={{
                            ...register("body", {
                                required: {
                                    value: true,
                                    message: "يجب ان يكون هناك محتوى للاشعار",
                                },
                            }),
                        }}
                        errorMessage={errors?.body?.message}
                        multiline={true}
                        minRows={3}
                        maxRows={8}
                    />
                    <SubmitButton label="ارسال" disabled={submitting} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>الغاء</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PushUserNotification;
