import * as yup from "yup";

export const editAdminSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, " الرقم السري قصير جدا")
        .required("ادخل الرقم السري الجديد"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], "تأكد من تطابق الرقم السري")
        .required("يجب تأكيد الرقم السرى"),
});
