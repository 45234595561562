import { useEffect, useState } from "react";
// react router
import { useParams, useNavigate } from "react-router";
// react readux
import { useSelector } from "react-redux";
// form
import {
    useForm,
    SubmitHandler,
    useFieldArray,
    Controller,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import {
    Stack,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Box,
    Button,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@mui/material";

// components
import {
    PageTitle,
    SideTitle,
    SubmitButton,
    InputField,
    SelectGender,
    SelectCity,
    ImagePreview,
    SelectArea,
    ThreeDotsLoader,
} from "../../components";
// schema
import { editDelivererSchema } from "../../schemes/deliverers/editDelivererSchema";
import { ReduxUserReducerState } from "../../types/global";
// icons
import {
    FaFacebookF,
    FaTwitter,
    FaWhatsapp,
    FaInstagram,
    FaTelegramPlane,
    FaPhoneAlt,
} from "react-icons/fa";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";
// form values
type FormValues = {
    img: File | null;
    name: string;
    address: {
        name: string;
        details: string;
        area: any | null;
    };
    disable: boolean;
    contactable: {
        key: string;
        value: string;
    }[];
};
const EditDeliverer = () => {
    const navigate = useNavigate();
    const { delivererId } = useParams<string>();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [submitting, setSubmitting] = useState(false);
    const [defaultImg, setDefaultImg] = useState("");
    const [loading, setLoading] = useState(true);
    // form
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            name: "",
            address: {
                name: "",
                details: "",
                area: null,
            },
            disable: false,
            contactable: [
                {
                    key: "",
                    value: "",
                },
            ],
        },
        resolver: yupResolver(editDelivererSchema),
        mode: "all",
    });
    const {
        register,
        control,
        getValues,
        setValue,
        reset,
        formState: { errors },
        handleSubmit,
    } = form;
    // use field array optoins
    const { fields, append, remove } = useFieldArray({
        control,
        name: "contactable",
    });
    // submit form
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        // stringify objects in contactable array
        const newContactable: any = [];
        data.contactable.forEach((item: any) => {
            newContactable.push(JSON.stringify(item));
        });
        // new data after config
        const newData = {
            ...data,
            address: JSON.stringify(data.address),
            contactable: newContactable,
        };
        // form data
        const formData = new FormData();
        for (let key in newData) {
            if (key === "contactable") {
                formData.append(key, `[${newData[key]}]`);
            } else {
                formData.append(key, newData[key]);
            }
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .put(`/admin/distributors/${delivererId}`, formData)
            .then((res: AxiosResponse) => {
                successAlert("تم تعديل الموزع");
                setSubmitting(false);
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    // get current deliverer data
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/distributors/${delivererId}`)
            .then((res: AxiosResponse) => {
                setDefaultImg(res.data.img);
                setLoading(false);
                reset({
                    img: res.data.img,
                    name: res.data.name,
                    address: {
                        name: res.data.address.name,
                        details: res.data.address.details,
                        area: {
                            id: res.data.address.area.id,
                            name: res.data.address.area.name,
                        },
                    },
                    disable: res.data.disable,
                    contactable: res.data.contactable,
                });
            });
    }, []);
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة موزع" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="700px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <SideTitle title="البيانات الاساسية" />
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message?.toString()}
                        IPWidth="200px"
                        defaultImage={defaultImg}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("name") }}
                        label="الاسم"
                        errorMessage={errors?.name?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("address.name") }}
                        label="العنوان"
                        errorMessage={errors?.address?.name?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("address.details") }}
                        label="العنوان بالتفصيل"
                        errorMessage={errors?.address?.details?.message}
                    />
                    <SelectArea
                        control={control}
                        errorMessage={errors?.address?.area?.message?.toString()}
                        getValues={getValues}
                        setValue={setValue}
                        name="address.area"
                    />
                    <SideTitle title="بيانات التواصل" />
                    {fields.map((field, index) => (
                        <Box
                            display="flex"
                            justifyContent="center"
                            gap="10px"
                            flexWrap="wrap"
                        >
                            <FormControl
                                error={
                                    errors?.contactable?.[index]?.key?.message
                                        ? true
                                        : false
                                }
                                sx={{ minWidth: "70px" }}
                            >
                                <InputLabel>المنصة</InputLabel>
                                <Controller
                                    name={`contactable.${index}.key`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select label="المنصة" {...field}>
                                            <MenuItem value="facebook">
                                                <FaFacebookF />
                                            </MenuItem>
                                            <MenuItem value="twitter">
                                                <FaTwitter />
                                            </MenuItem>
                                            <MenuItem value="instagram">
                                                <FaInstagram />
                                            </MenuItem>
                                            <MenuItem value="telegram">
                                                <FaTelegramPlane />
                                            </MenuItem>
                                            <MenuItem value="phone">
                                                <FaPhoneAlt />
                                            </MenuItem>
                                            <MenuItem value="whatsapp">
                                                <FaWhatsapp />
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                                {errors?.contactable?.[index]?.key?.message ? (
                                    <FormHelperText id="gender-error-msg">
                                        {
                                            errors?.contactable?.[index]?.key
                                                ?.message
                                        }
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )}
                            </FormControl>
                            <InputField
                                type="text"
                                registration={{
                                    ...register(`contactable.${index}.value`),
                                }}
                                label="القيمة"
                                errorMessage={
                                    errors?.contactable?.[index]?.value?.message
                                }
                                styles={{ flex: "1", minWidth: "250px" }}
                            />
                            {/* <Button
                                onClick={() => remove(index)}
                                color="error"
                                variant="outlined"
                            >
                                حذف
                            </Button> */}
                        </Box>
                    ))}
                    <Button
                        onClick={() => append({ key: "facebook", value: "" })}
                        color="secondary"
                        variant="outlined"
                    >
                        اضافة وسيلة تواصل
                    </Button>
                    <FormControlLabel
                        control={<Checkbox {...register("disable")} />}
                        label="تعطيل"
                    />
                    <SubmitButton label="تعديل" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default EditDeliverer;
