import React, { useState, useEffect } from "react";
//  react redux
import { useSelector } from "react-redux";
// types
import { ReduxUserReducerState } from "../../types/global";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// components
import ThreeDotsLoader from "../loader/ThreeDotsLoader";
import StatsticsCard from "../card/StatsticsCard";
import { Box } from "@mui/material";

interface IOtpInfo {
    account_expiry: string;
    account_key: string;
    company: string;
    created_at: string;
    email: string;
    id: string;
    img: string;
    is_deleted: boolean;
    is_disabled: boolean;
    is_notifiable: boolean;
    is_verified: boolean;
    name: string;
    permissions: number[];
    phone: any;
    prod_calls: number;
    prod_stock: number;
    ref: string;
    roles: number[];
    test_calls: number;
    test_stock: number;
    thumb: string;
    updated_at: string;
    wallet: IWallet;
}

interface IWallet {
    credit: number;
    trx_count_credit: number;
    trx_count_debit: number;
    trx_total_credit: number;
    trx_total_debit: number;
}

const OtpInfo = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [otpInfo, setOtpInfo] = useState<IOtpInfo | any>({});
    const [loading, setLoading] = useState(true);
    // get all areas
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/otp/me`)
            .then((res: AxiosResponse) => {
                setOtpInfo(res.data);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, []);
    // while fetching data
    if (loading) return <ThreeDotsLoader />;
    return (
        <Box display="flex" justifyContent="center" gap="20px" flexWrap="wrap">
            <StatsticsCard
                title="عدد الرسائل المرسلة"
                value={otpInfo?.prod_calls ?? ""}
            />
            <StatsticsCard
                title="الرصيد المتبقي"
                value={otpInfo?.wallet?.credit ?? ""}
                moreStyles={{
                    background:
                        Number(otpInfo?.wallet?.credit) < 10 ? "red" : "",
                }}
            />
        </Box>
    );
};

export default OtpInfo;
