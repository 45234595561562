import React, { useState, SetStateAction, Dispatch, ChangeEvent } from "react";
// redux
import { useSelector } from "react-redux";
// router
import { Link } from "react-router-dom";
// mui
import {
    Stack,
    Typography,
    Box,
    IconButton,
    Button,
    Switch,
} from "@mui/material";
// icnos
import { MdOutlineModeEditOutline, MdOutlineDelete } from "react-icons/md";
// alerts
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";

type Props = {
    id: string;
    question: string;
    answer: string;
    categoryName: string;
    setDataChanged: Dispatch<SetStateAction<boolean>>;
    checked: boolean;
};
const QuestionCard = ({
    id,
    question,
    answer,
    categoryName,
    setDataChanged,
    checked,
}: Props) => {
    // state
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [disabledValue, setDisabledValue] = useState(!checked);
    // deleting function
    const deletingProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(`/admin/faq/${id}`)
            .then((res: AxiosResponse) => {
                successAlert("تم حذف السؤال");
                setDataChanged((prev) => !prev);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };
    const confirmDeleting = () => {
        askForConfirmationAlert(
            `هل انت متاكد انك تريد حذف السؤال ${question}`,
            deletingProcess
        );
    };
    // change status
    const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = !e.target.checked;
        const formData: any = new FormData();
        formData.append("is_disabled", v);
        EnhancedAxios(null, userToken)
            .put(`/admin/faq/${id}`, formData)
            .then((res: any) => {
                const alertMsg =
                    res.data.is_disabled === true ? "تم التعطيل" : "تم التفعيل";
                successAlert(alertMsg);
                setDisabledValue(!res.data.is_disabled);
            });
    };
    return (
        <Stack
            gap="10px"
            borderRadius="5px"
            padding="20px"
            width="100%"
            sx={{
                boxShadow: 2,
                border: "1px solid #ddd",
                opacity: !disabledValue ? "0.7" : "1.0",
            }}
        >
            <Stack>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Typography variant="h6">{question}</Typography>
                    <Box display="flex" gap="10px">
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            sx={{
                                fontSize: "20px",
                                minWidth: "30px",
                            }}
                            component={Link}
                            to={`/edit-question/${id}`}
                        >
                            <MdOutlineModeEditOutline />
                        </Button>
                        {/* <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            sx={{
                                fontSize: "20px",
                                minWidth: "30px",
                            }}
                            onClick={confirmDeleting}
                        >
                            <MdOutlineDelete />
                        </Button> */}
                        <Switch
                            size="small"
                            checked={disabledValue}
                            onChange={handleStatusChange}
                        />
                    </Box>
                </Box>
                <Typography
                    variant="body1"
                    width="fit-content"
                    sx={{
                        backgroundColor: "primary.main",
                        padding: "0px 5px",
                        color: "white",
                        borderRadius: "2px",
                    }}
                >
                    {categoryName}
                </Typography>
            </Stack>
            <Typography variant="body2">{answer}</Typography>
        </Stack>
    );
};

export default QuestionCard;
