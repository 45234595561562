import React, { useState, useEffect } from "react";
// react router
import { Link } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import { Stack, Box, Divider, Button } from "@mui/material";
// componets
import {
    OtpInfo,
    PageTitle,
    StatsticsCard,
    ThreeDotsLoader,
} from "../../components";
// alerts
import { errorAlert } from "../../utils/alerts/alerts";
// types
import { ReduxUserReducerState } from "../../types/global";
type Statistics = {
    total_invoices: number;
    total_items: number;
    total_users: number;
    total_distributors: number;
    total_notifications: number;
};
const Home = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [statistics, setStatistics] = useState<Statistics>({
        total_invoices: 0,
        total_items: 0,
        total_users: 0,
        total_distributors: 0,
        total_notifications: 0,
    });
    const [loading, setLoading] = useState(true);
    // get all areas
    useEffect(() => {
        setLoading(true);
        EnhancedAxios(null, userToken)
            .get(`/admin/statistics`)
            .then((res: AxiosResponse) => {
                setStatistics(res.data);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    }, []);
    // while fetching data
    if (loading) return <ThreeDotsLoader />;
    return (
        <Stack spacing="40px">
            <PageTitle title="الصفحة الرئيسية" />
            <Box
                display="flex"
                justifyContent="center"
                gap="20px"
                flexWrap="wrap"
            >
                <StatsticsCard
                    title="عدد الطلبات"
                    value={statistics?.total_invoices}
                />
                <StatsticsCard
                    title="عدد الاصناف"
                    value={statistics?.total_items}
                />
                <StatsticsCard
                    title="عدد المستخدمين"
                    value={statistics?.total_users}
                />
                <StatsticsCard
                    title="عدد الاشعارات"
                    value={statistics?.total_notifications}
                />
                <StatsticsCard
                    title="عدد الموزعين"
                    value={statistics?.total_distributors}
                />
            </Box>
            <Divider />
            <OtpInfo />
            <Stack alignItems="center">
                <Button
                    variant="outlined"
                    component={Link}
                    to="/otp-history-messages"
                >
                    سجل الرسائل المرسلة
                </Button>
            </Stack>
        </Stack>
    );
};

export default Home;
