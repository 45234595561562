import { useState } from "react";
// react router
import { useNavigate } from "react-router";
// react readux
import { useSelector } from "react-redux";
// form
import {
    useForm,
    SubmitHandler,
    useFieldArray,
    Controller,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// axios
import EnhancedAxios from "../../axios/EnhancedAxios";
import { AxiosResponse, AxiosError } from "axios";
// mui
import {
    Stack,
    FormControlLabel,
    Select,
    MenuItem,
    Box,
    Button,
    FormControl,
    InputLabel,
    FormHelperText,
    Switch,
} from "@mui/material";

// components
import {
    PageTitle,
    SideTitle,
    SubmitButton,
    InputField,
    SelectGender,
    SelectCity,
    ImagePreview,
    SelectArea,
} from "../../components";
// schema
import { addDelivererSchema } from "../../schemes/deliverers/addDelivererSchema";
import { ReduxUserReducerState } from "../../types/global";
// icons
import {
    FaFacebookF,
    FaTwitter,
    FaWhatsapp,
    FaInstagram,
    FaTelegramPlane,
    FaPhoneAlt,
} from "react-icons/fa";
// alerts
import { errorAlert, successAlert } from "../../utils/alerts/alerts";

// form values
type FormValues = {
    img: File | null;
    name: string;
    address: {
        name: string;
        details: string;
        // city: any | null;
        area: any | null;
        location: {
            type: string;
            coordinates: any;
        };
    };
    is_disabled: boolean;
    contactable: {
        key: string;
        value: string;
    }[];
};

const AddDeliverer = () => {
    const navigate = useNavigate();
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [resetPreview, setResetPreview] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    // form
    const form = useForm<FormValues>({
        defaultValues: {
            img: null,
            name: "",
            address: {
                name: "",
                details: "",
                // city: null,
                area: null,
                location: {
                    type: "Point",
                    coordinates: [22.214569, 31.125698],
                },
            },
            is_disabled: false,
            contactable: [
                {
                    key: "",
                    value: "",
                },
            ],
        },
        resolver: yupResolver(addDelivererSchema),
        mode: "all",
    });
    const {
        register,
        control,
        getValues,
        setValue,
        reset,
        watch,
        formState: { errors },
        handleSubmit,
    } = form;
    // use field array optoins
    const { fields, append, remove } = useFieldArray({
        control,
        name: "contactable",
    });
    // submit form
    const onSubmit: SubmitHandler<FormValues> = (data: any) => {
        // stringify objects in contactable array
        const newContactable: any = [];
        data.contactable.forEach((item: any) => {
            newContactable.push(JSON.stringify(item));
        });
        // new data after config
        const newData = {
            ...data,
            address: JSON.stringify(data.address),
            contactable: newContactable,
        };
        // form data
        const formData = new FormData();
        for (let key in newData) {
            if (key === "contactable") {
                formData.append(key, `[${newData[key]}]`);
            } else {
                formData.append(key, newData[key]);
            }
        }
        setSubmitting(true);
        EnhancedAxios("multipart/form-data", userToken)
            .post(`/admin/distributors`, formData)
            .then((res: AxiosResponse) => {
                successAlert("تم اضافة مووزع");
                setSubmitting(false);
                reset();
                setResetPreview(true);
                navigate(-1);
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
                setSubmitting(false);
            });
    };
    return (
        <Stack gap="20px">
            <PageTitle title="اضافة موزع" />
            <Stack alignItems="center">
                <Stack
                    width="100%"
                    maxWidth="700px"
                    gap="10px"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <SideTitle title="البيانات الاساسية" />
                    <ImagePreview
                        setValue={setValue}
                        errorMessage={errors?.img?.message?.toString()}
                        IPWidth="200px"
                        resetPreview={resetPreview}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("name") }}
                        label="الاسم"
                        errorMessage={errors?.name?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("address.name") }}
                        label="العنوان"
                        errorMessage={errors?.address?.name?.message}
                    />
                    <InputField
                        type="text"
                        registration={{ ...register("address.details") }}
                        label="العنوان بالتفصيل"
                        errorMessage={errors?.address?.details?.message}
                    />
                    {/* <SelectCity
                        control={control}
                        errorMessage={errors?.address?.city?.message?.toString()}
                        getValues={getValues}
                        setValue={setValue}
                        name="address.city"
                    /> */}
                    <SelectArea
                        control={control}
                        errorMessage={errors?.address?.area?.message?.toString()}
                        getValues={getValues}
                        setValue={setValue}
                        name="address.area"
                    />
                    <SideTitle title="بيانات التواصل" />
                    {fields.map((item, index) => (
                        <Box
                            id={item.id}
                            display="flex"
                            justifyContent="center"
                            gap="10px"
                            flexWrap="wrap"
                        >
                            <FormControl
                                error={
                                    errors?.contactable?.[index]?.key?.message
                                        ? true
                                        : false
                                }
                                sx={{ minWidth: "70px" }}
                            >
                                <InputLabel>المنصة</InputLabel>
                                <Controller
                                    name={`contactable.${index}.key`}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            label="المنصة"
                                            {...field}
                                            value={watch(
                                                `contactable.${index}.key`
                                            )}
                                        >
                                            <MenuItem value="facebook">
                                                <FaFacebookF />
                                            </MenuItem>
                                            <MenuItem value="twitter">
                                                <FaTwitter />
                                            </MenuItem>
                                            <MenuItem value="instagram">
                                                <FaInstagram />
                                            </MenuItem>
                                            <MenuItem value="telegram">
                                                <FaTelegramPlane />
                                            </MenuItem>
                                            <MenuItem value="phone">
                                                <FaPhoneAlt />
                                            </MenuItem>
                                            <MenuItem value="whatsapp">
                                                <FaWhatsapp />
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                                {errors?.contactable?.[index]?.key?.message ? (
                                    <FormHelperText id="gender-error-msg">
                                        {
                                            errors?.contactable?.[index]?.key
                                                ?.message
                                        }
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )}
                            </FormControl>
                            <InputField
                                type="text"
                                registration={{
                                    ...register(`contactable.${index}.value`),
                                }}
                                label="القيمة"
                                errorMessage={
                                    errors?.contactable?.[index]?.value?.message
                                }
                                styles={{ flex: "1", minWidth: "250px" }}
                            />
                            <Button
                                onClick={() => remove(index)}
                                color="error"
                                variant="outlined"
                            >
                                حذف
                            </Button>
                        </Box>
                    ))}
                    <Button
                        onClick={() => append({ key: "facebook", value: "" })}
                        color="secondary"
                        variant="outlined"
                    >
                        اضافة وسيلة تواصل
                    </Button>
                    <Controller
                        name="is_disabled"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        {...field}
                                        checked={!watch("is_disabled")}
                                        onChange={(e) =>
                                            setValue(
                                                "is_disabled",
                                                !e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="مفعل"
                            />
                        )}
                    />
                    <SubmitButton label="انشاء" disabled={submitting} />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AddDeliverer;
