import React, { useEffect, useState } from "react";
// react redux
import { useSelector } from "react-redux";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { editAdminSchema } from "../../../schemes/setting/editAdminSchema";
// mui
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Stack,
} from "@mui/material";
// components
import InputField from "../../form/InputField";
import SubmitButton from "../../button/SubmitButton";
// types
import { ReduxUserReducerState } from "../../../types/global";
import ThreeDotsLoader from "../../loader/ThreeDotsLoader";
import EnhancedAxios from "../../../axios/EnhancedAxios";
import { AxiosError, AxiosResponse } from "axios";
import { errorAlert, successAlert } from "../../../utils/alerts/alerts";

type FormValues = {
    password: string;
    password_confirmation: string;
};
const EditAdminData = () => {
    const userToken = useSelector(
        (state: ReduxUserReducerState) => state.user.userToken
    );
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [adminData, setAdminData] = useState({ email: "", id: "" });
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get("/me")
            .then((res: AxiosResponse) => {
                console.log("/me res", res.data);
                setAdminData({ email: res?.data?.email, id: res?.data?.id });
                setLoading(false);
            })
            .catch((err: AxiosError) => {
                console.log(err);
            });
    }, []);
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            password: "",
            password_confirmation: "",
        },
        resolver: yupResolver(editAdminSchema),
        mode: "onSubmit",
    });
    const submitting: SubmitHandler<FormValues> = (values) => {
        EnhancedAxios("application/json", userToken)
            .put(`/admin/users/${adminData.id}`, values)
            .then((res: AxiosResponse) => {
                successAlert("تم تعديل كلمة المرور خاصة الادارة ");
            })
            .catch((error: AxiosError) => {
                errorAlert(error);
            });
    };

    if (loading) return <ThreeDotsLoader />;
    return (
        <>
            <Button
                variant="outlined"
                sx={{ width: "fit-content" }}
                onClick={handleOpen}
            >
                تعديل بيانات الادارة
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>تعديل كلمة المرور</DialogTitle>
                <DialogContent
                    sx={{
                        minWidth: { xs: "290px", sm: "500px" },
                        width: "100%",
                    }}
                >
                    <Stack
                        py={2}
                        gap="16px"
                        component="form"
                        onSubmit={handleSubmit(submitting)}
                    >
                        <TextField
                            name="password"
                            value={adminData.email}
                            label="البريد الالكتروني"
                            inputProps={{ readOnly: true }}
                        />
                        <InputField
                            type="password"
                            registration={{ ...register("password") }}
                            label="كلمة المرور"
                            errorMessage={errors?.password?.message}
                        />
                        <InputField
                            type="password"
                            registration={{
                                ...register("password_confirmation"),
                            }}
                            label="تآكيد كلمة المرور"
                            errorMessage={
                                errors?.password_confirmation?.message
                            }
                        />
                        <SubmitButton label="حفظ" disabled={isSubmitting} />
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EditAdminData;
