import React from "react";
// mui
import { Stack, Box, Typography } from "@mui/material";
// react router
import { NavLink } from "react-router-dom";
// componetns
import NavbarLink from "./NavbarLink";
// links
import { adminLinks } from "../../utils/navbar/navbarLinks";

const NavbarLinks = () => {
    return (
        <Stack width="100%">
            {adminLinks.map((lnk) => (
                <NavbarLink
                    key={lnk.path}
                    path={lnk.path}
                    title={lnk.title}
                    icon={lnk.icon}
                />
            ))}
        </Stack>
    );
};

export default NavbarLinks;
