import React from "react";
// component
import { LinkButton, IsVerifiedSwitch } from "../../components";
// mui
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Button } from "@mui/material";
// icons
import { HiOutlineBell } from "react-icons/hi";
// store
import store from "../../redux/store";
import {
    setOpen,
    setNotifyUserData,
} from "../../redux/features/notify/notifySlice";
// moment
import moment from "moment";

const notifyUser = (id: string, name: string) => {
    store.dispatch(setOpen(true));
    const obj = {
        id: id,
        name: name,
    };
    store.dispatch(setNotifyUserData(obj));
};

export const usersCols: GridColDef[] = [
    {
        field: "name",
        headerName: "الاسم",
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? "لا يوجد",
    },
    {
        field: "email",
        headerName: "البريد الالكترونى",
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.email ?? "لا يوجد",
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? "لا يوجد",
    },
    {
        field: "created_at",
        headerName: "تاريخ الانشاء ",
        width: 170,
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD HH:MM")
                : "لا يوجد",
    },
    {
        field: "pushNotification",
        headerName: "ارسال اشعار",
        width: 90,
        sortable: false,
        filterable: false,
        renderCell: (params: any): React.ReactNode => {
            const id = params.row?.id;
            const name = params.row?.name;
            return React.createElement(
                Button,
                {
                    size: "large",
                    style: {
                        fontSize: "22px",
                    },
                    onClick: (e: any) => notifyUser(id, name),
                },
                React.createElement(HiOutlineBell, {})
            );
        },
    },
    {
        field: "edit",
        headerName: "تعديل الطلب",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(LinkButton, {
                text: "تعديل",
                to: `/edit-user/${params.row.id}`,
                size: "small",
                variant: "outlined",
            }),
    },
    {
        field: "is_verified",
        headerName: "متحقق",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(IsVerifiedSwitch, {
                id: params?.row?.id,
                is_verified: params?.row?.is_verified,
            }),
    },
];
