import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ChangeOrderStatus, LinkButton } from "../../components";
import { translateToArabic } from "../localize/englishToArabic";
import moment from "moment";
export const ordersCols: GridColDef[] = [
    {
        field: "orderNumber",
        headerName: "رقم الطلب",
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.friendly_id ?? "لا يوجد",
    },
    // {
    //     field: "status",
    //     headerName: "حالة الطلب",
    //     width: 120,
    //     sortable: false,
    //     filterable: false,
    //     valueGetter: (params) =>
    //         params.row?.status
    //             ? translateToArabic(params?.row?.status)
    //             : "لا يوجد",
    // },
    {
        field: "total_items_price",
        headerName: "اجمالى سعر الطلبات",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.total_items_price ?? "لا يوجد",
    },
    {
        field: "delivery_cost",
        headerName: "سعر التوصيل",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.delivery_cost ?? "لا يوجد",
    },
    {
        field: "total_discount_value",
        headerName: "اجمالى قيمة التخفيض",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.total_discount_value ?? "لا يوجد",
    },
    {
        field: "total_invoice_cost",
        headerName: "اجمالى قيمة الطلب",
        width: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.total_invoice_cost ?? "لا يوجد",
    },
    {
        field: "created_at",
        headerName: " تاريخ الطلب",
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row.created_at).format("YYYY/MM/DD HH:MM")
                : "لا يوجد",
    },
    {
        field: "status",
        headerName: "حالة الطلب",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(ChangeOrderStatus, {
                id: params?.row?.id,
                defaultValue: params?.row?.status ?? "",
            }),
    },
    {
        field: "detials",
        headerName: "تفاصيل الطلب",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<any>): React.ReactNode =>
            React.createElement(LinkButton, {
                text: "مشاهدة",
                to: `/show-order/${params.row.id}`,
                size: "small",
                variant: "outlined",
            }),
    },
];
