import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReduxUserIntialState } from "../../../types/global";
// type IntialState = {
//     userToken: String | null;
// };

const initialState: ReduxUserIntialState = {
    userToken: localStorage.getItem("flexnet_user_token") ?? "",
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserToken: (state, action: PayloadAction<string>) => {
            state.userToken = action.payload;
            localStorage.setItem(
                "flexnet_user_token",
                action.payload.toString()
            );
        },
        removeUserToken: (state) => {
            state.userToken = "";
            localStorage.removeItem("flexnet_user_token");
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUserToken, removeUserToken } = userSlice.actions;

export default userSlice.reducer;
